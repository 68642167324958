import { Divider, Form, Modal, Popconfirm, Spin, Switch } from "antd";
import {
  MODULE_KEY,
  fetchProductPlanogramMapping,
  productPlanogramImportFile,
} from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import {
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../../styles/vendor.scss";
import { useDispatch, useSelector } from "react-redux";
import AddPPMapping from "./AddPPMapping";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { ACTION, ImportType } from "src/util/utils";
import { deletePPItems, getProductPlanogram } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { ValidationModal } from "../OfficeCollection/ValidationModal";
import moment from "moment";
import { PRODUCT_PLANOGRAM_EXCEL_COLUMNS } from "src/util/columns";
import ColumnDrawer from "./ColumnDrawer";
import { useCookies } from "react-cookie";
import Label from "src/components/common/Label/Label";
const ExcelJS = require("exceljs");

function ProductPlanogram() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["pp-columns"]);
  const [tableColumns, setTableColumns] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [draweVisible, setDrawerVisible] = useState(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState(false);
  const [exportFile, setExportFile] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const stateKey = `${MODULE_KEY}${ACTION.STATE_KEY}`;
  const [showActive, setShowActive] = useState(true);
  const editDrawer = (record: any) => {
    setVisible(true);
    const res = getProductPlanogram(record);
    res.then((response: any) => {
      setModalData(response);
    });
  };
  const deleteModal = (record: any) => {
    setOpen(true);
    setDeleteData(record?.ID1);
  };
  let importData = useSelector(
    (state: any) => state[stateKey]
  ).importValidationData;

  useEffect(() => {
    dispatch(fetchProductPlanogramMapping(showActive));
  }, []);
  useEffect(() => {
    dispatch(fetchProductPlanogramMapping(showActive));
  }, [showActive]);
  useEffect(() => {
    if (
      importData.Failed_Count === 0 &&
      importData.Success_Count === importData.Total_Count
    ) {
      showSuccessNotification("Data added successfully.");
      dispatch(fetchProductPlanogramMapping(showActive));
    } else if (importData.Failed_Count > 0) {
      setErrorModal(true);
    }
  }, [importData]);
  const getOnFilter = (cookiesData: any) => {
    const onFlterAdded = cookiesData?.map((item: any) => {
      if (item?.filters) {
        return {
          ...item,
          ...{
            onFilter: (value: any, record: { [x: string]: string | any[] }) => {
              return record[item?.dataIndex]?.indexOf(value) === 0;
            },
          },
        };
      } else {
        return item;
      }
    });
    return onFlterAdded;
  };

  let filterValues = filterColumns(editDrawer, deleteModal);
  useEffect(() => {
    const actionColumn = filterValues?.columns?.find(
      (item: any) => item.title === "action"
    );
    const assortmentColumn = filterValues?.columns?.find(
      (item: any) => item.dataIndex === "Assortment"
    );
    const ImagesColumn = filterValues?.columns?.find(
      (item: any) => item.dataIndex === "Images"
    );
    const updatedColumns = cookies["pp-columns"]?.length
      ? [
          ImagesColumn,
          ...getOnFilter(cookies["pp-columns"])?.filter(
            (col: any) =>
              col.title !== "action" &&
              col.dataIndex !== "Assortment" &&
              col.dataIndex !== "Images"
          ),
          assortmentColumn,
          actionColumn,
        ]
      : filterValues?.columns;
    setTableColumns(updatedColumns);
  }, [cookies]);

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExportFile(true);
  };

  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setOpen(false);
    dispatch(fetchProductPlanogramMapping(showActive));
  };

  const exportExcelFile = async () => {
    setConfirmLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = PRODUCT_PLANOGRAM_EXCEL_COLUMNS;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    try {
      if (data?.length > 0) {
        setExportFile(false);
        setConfirmLoading(false);
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              ...item,
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Product-Planogram-Report.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    } finally {
      setConfirmLoading(false);
      setExportFile(false);
    }
  };
  const handleDelete = async (e: any) => {
    setConfirmLoading(true);
    try {
      const res = await deletePPItems(deleteData);
      if (res) {
        showSuccessNotification(res);
        handleCancel();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data);
    }
    setConfirmLoading(false);
  };
  const statusLabel = showActive ? "Show Active SKU" : "Show Inactive SKU";
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Product Planogram</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex">
            <Label
              label={statusLabel}
              className="flex-row align-items-center fs-6 gap-3"
            >
              <Switch
                size="small"
                checked={showActive}
                onChange={(flag) => {
                  setShowActive(flag);
                }}
              />
            </Label>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={closeModal}
              disabled={filteredData[0] === "none"}
            >
              {" "}
              Export
            </Button>
            <Button
              size="middle"
              icon={<UploadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => setOpenUpload(true)}
            >
              {" "}
              Import
            </Button>
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={addDrawer}
            >
              {" "}
              Add New
            </Button>
            <Button
              size="middle"
              // icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => setDrawerVisible(true)}
            >
              {" "}
              Column Management
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="ID1"
          columns={tableColumns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 500,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
          }}
          filteredData={true}
          setFilteredData={setFilteredData}
        />
        <AddPPMapping
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
        />
        <UploadModal
          visible={openUpload}
          onCancel={() => setOpenUpload(false)}
          stateKey={`${MODULE_KEY}${ACTION.STATE_KEY}`}
          importFile={productPlanogramImportFile}
        />
        <ValidationModal
          visible={errorModal}
          data={importData}
          onCancel={() => setErrorModal(false)}
          importType={ImportType.PlanogramImport}
        />
        <ColumnDrawer
          visible={draweVisible}
          setVisibleStatus={setDrawerVisible}
          filterValues={filterValues}
          setTableColumns={setTableColumns}
          cookies={cookies}
          setCookie={setCookie}
          removeCookie={removeCookie}
        />
        <Modal
          title="Excel export Confirmation"
          okText="Ok"
          open={exportFile}
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          onOk={() => exportExcelFile()}
          onCancel={() => setExportFile(false)}
        >
          <span>Are you sure you want to export this data?</span>
        </Modal>
        <Modal
          title="Delete Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={open}
          onOk={handleDelete}
          onCancel={() => setOpen(false)}
        >
          <span>
            Are you sure you wish to delete this item from the product
            planogram?
          </span>
        </Modal>
      </Spin>
    </>
  );
}
export default ProductPlanogram;
