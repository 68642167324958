import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, UploadFile, UploadProps } from "antd";
import { Image, Modal, Upload } from "antd";
import styled from "styled-components";
import { showErrorNotification } from "src/util/notifications";

const { Dragger } = Upload;

export const UploadImages = ({
  onCancel,
  visible,
  fileList,
  setFileList,
  confirmLoading,
  setConfirmLoading,
}: any) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isValid, setIsValid] = useState(false);

  const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleChanges = (options: any) => {
    if (!isValid) {
      setFileList(options.fileList);
    }
  };

  const handleCancel = () => {
    setTimeout(() => onCancel(), 10);
  };

  const handleClose = () => {
    // setFileList([]);
    onCancel();
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const handleRemove = (file: UploadFile) => {
    const index = fileList.indexOf(5);
    if (index > -1) {
      fileList.splice(index, 1);
    }
  };
  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file: any) => {
    const allowedFormats = ["image/png", "image/jpeg", "image/gif"].includes(
      file.type
    );
    const isValidSize = file.size / 1024 / 1024 < 10;
    if (fileList?.length <= 9) {
      const found = fileList?.some((item: any) => item.name === file.name);
      if (!allowedFormats) {
        showErrorNotification("Please upload only PNG, JPG, or GIF files");
        setIsValid(true);
        return;
      } else if (!isValidSize) {
        showErrorNotification("Image must be smaller than 10MB!");
        setIsValid(true);
        return;
      } else if (found) {
        showErrorNotification(`${file.name} is already exist`);
        setIsValid(true);
        return;
      } else {
        setIsValid(false);
      }
    } else {
      showErrorNotification("You can upload a maximum of 10 images at a time");
      setIsValid(true);
      return;
    }
  };
  return (
    <StyledImportModal
      title="Upload Images"
      visible={visible}
      onCancel={handleClose}
      centered
      footer={
        <Button type={"ghost"} onClick={handleClose}>
          Ok
        </Button>
      }
      width={512}
    >
      <div>
        <Dragger
          // multiple
          className="mb-2"
          customRequest={dummyRequest}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={(file: any) => handleBeforeUpload(file)}
          onChange={handleChanges}
          onPreview={handlePreview}
          onRemove={handleRemove}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          {confirmLoading ? (
            <p className="ant-upload-hint">Processing...</p>
          ) : (
            <>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for single or bulk uploads of PNG, JPG, and GIF files is
                available. Users can upload a maximum of 10 images at a time.
              </p>
            </>
          )}
        </Dragger>
      </div>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (
              visible: boolean | ((prevState: boolean) => boolean)
            ) => setPreviewOpen(visible),
            //  onPreviewClose: (visible: any) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </StyledImportModal>
  );
};

const StyledImportModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
  }
`;
